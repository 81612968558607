import styled from 'styled-components'

export const Container = styled.div<{ selected: boolean; hover: boolean; hideOnMobile?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 44px;
  margin: 5px 0;
  border-radius: 5px;
  background-color: ${({ selected, hover }) => {
    if (selected) return 'rgba(80, 136, 192, 1)'
    if (hover) return 'rgba(80, 136, 192, 0.3)'
  }};
  color: white;
  font-family: AzoSans;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;

  @media screen and (max-width: 600px) {
    display: ${({ hideOnMobile }) => (hideOnMobile ? 'none' : 'flex')};
  }
`

export const IconContainer = styled.div`
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`
