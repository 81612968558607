import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

import { format as formatDate, isDate } from 'date-fns'
import { enGB, fr, es } from 'date-fns/locale'

// Available locales for translation and localization
// Corresponding translation files must be created in /public/locales/<language_without_locale>/<namespace>.json
export const availableLocales = {
  'en-GB': {
    name: 'English (UK)',
    dateFnsLocale: enGB,
  },
  'fr-FR': {
    name: 'Français',
    dateFnsLocale: fr,
  },
  'es-ES': {
    name: 'Español',
    dateFnsLocale: es,
  },
}
const defaultLocale = 'en-GB'

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      // to reduce translation work, there is one translation per language and not per locale
      // => locale en-US and en-GB will use translation 'en'
      loadPath: (lang: string[], ns: string[]) => {
        const langWithoutCountry = lang[0].split('-', 2)[0]
        return `/locales/${langWithoutCountry}/${ns[0]}.json`
      },
    },
    supportedLngs: Object.getOwnPropertyNames(availableLocales),
    partialBundledLanguages: true,
    ns: ['common', 'legacy'],
    defaultNS: 'legacy',
    fallbackNS: 'common',
    fallbackLng: defaultLocale,
    debug: !!process.env.REACT_APP_I18N_DEBUG,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default

      // Use Date-Fns to translate and format dates passed as translation variables
      // e.g.: `{ "postedOn": "Posted on {{ date, MM/YY/DDDD }}"}`
      // based on https://dev.to/ekeijl/react-automatic-date-formatting-in-translations-i18next-date-fns-8df
      format: (value, format?: string, lng?: string) => {
        if (isDate(value)) {
          const locale = (availableLocales as any)[lng ? lng : defaultLocale].dateFnsLocale

          if (format === 'shortDate') return formatDate(value, 'P', { locale })
          if (format === 'longDate') return formatDate(value, 'PPPP', { locale })
          if (format === 'time') return formatDate(value, 'p', { locale })

          if (!format) return formatDate(value, 'Ppppp', { locale })

          return formatDate(value, format, { locale })
        }
        return value
      },
    },
  })

export default i18n
