import {
  USER_INFOS_LOADED_ERROR,
  USER_INFOS_LOADED_SUCCESS,
  USER_INFOS_LOADING,
  USER_LOGIN_LOADED_ERROR,
  USER_LOGIN_LOADED_SUCCESS,
  USER_LOGIN_LOADING,
  USER_SIGN_OUT,
  UserActionTypes,
} from './actionTypes'

import IUser from '../../_types/user'

export interface IUserState {
  loading: number
  data: IUser
  errorCode: number
}

const defaultState: IUserState = {
  loading: 0,
  data: {},
  errorCode: 0,
}

const user = (state: IUserState = defaultState, action: UserActionTypes): IUserState => {
  switch (action.type) {
    case USER_LOGIN_LOADING:
    case USER_INFOS_LOADING:
      return { ...state, loading: 1 }
    case USER_LOGIN_LOADED_SUCCESS:
      return { ...state, loading: 0, errorCode: 0 }
    case USER_INFOS_LOADED_SUCCESS:
      return { ...state, loading: 0, data: { ...state.data, ...action.data }, errorCode: 0 }
    case USER_LOGIN_LOADED_ERROR:
    case USER_INFOS_LOADED_ERROR:
      return { ...state, loading: -1, errorCode: action.errorCode }
    case USER_SIGN_OUT:
      return defaultState
    default:
      return state
  }
}

export default user
