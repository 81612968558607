import React from 'react'
import { Route, useRouteMatch } from 'react-router-dom'

import Header from './Header'
import NotificationCenter from './NotificationCenter'
import SideBar from './SideBar'
import Welcome from './Welcome'

import Toast from '../../_ui/Toast'

import Routing from './routing'

import { AppContainer, Container, MainContainer } from './MainLayout.styled'

const MainLayout = () => {
  const { path } = useRouteMatch()

  return (
    <Container>
      <Header />
      <AppContainer>
        <SideBar />
        <MainContainer id="main-container">
          <Route path={path} component={Routing} />
          <Toast />
        </MainContainer>
        {!!process.env.REACT_APP_NOTIFICATION_CENTER && <NotificationCenter />}
      </AppContainer>
      <Welcome />
    </Container>
  )
}

export default MainLayout
