import React from 'react'

import CircularProgress from '@material-ui/core/CircularProgress'

import { Container } from './Refresh.styled'

import Empty from './Empty'
import Error from './Error'

interface IProps {
  loading: 0 | 1 | -1
  onRefresh: () => void
  children?: JSX.Element
  small?: boolean
  empty: {
    Icon?: React.ComponentType<any>
    label: string
  }
}

const Refresh = (props: IProps) => {
  const { loading, onRefresh, children, empty, small } = props

  const onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    onRefresh()
  }

  return children ? (
    children
  ) : (
    <Container
      style={{ height: small ? 100 : 200 }}
      loadingData={loading === 1}
      onClick={loading !== 1 ? onClick : undefined}
    >
      {loading === 1 ? (
        <CircularProgress />
      ) : (
        <React.Fragment>{loading === -1 ? <Error small={small} /> : <Empty {...empty} small={small} />}</React.Fragment>
      )}
    </Container>
  )
}

export default Refresh
