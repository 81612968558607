import React from 'react'
import { connect as reduxConnect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { AppState } from './store'

const mapDispatchToProps = (actions: {}) => (dispatch: Dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
})

const connect = (getState?: (s: AppState) => {}, actions?: {}) => (WrappedComponent: React.ComponentType<any>) =>
  reduxConnect(getState, actions ? mapDispatchToProps(actions) : undefined)(WrappedComponent)

export default connect
