import INotification from '../../_types/notification'

const data: INotification[] = [
  {
    id: '1',
    date: new Date(),
    data: {
      id: '1',
      type: 'booking',
      title: 'New booking',
      content: 'for Snowmobile day excursion in Tromso with dolphin',
    },
  },
  {
    id: '2',
    date: new Date(),
    unread: true,
    data: {
      id: '1',
      type: 'booking',
      title: 'New booking',
      content: 'for Snowmobile day excursion in Tromso with dolphin',
    },
  },
  {
    id: '3',
    date: new Date(),
    data: {
      id: '1',
      type: 'review',
      title: 'New review',
      content: 'for Snowmobile day excursion in Tromso with dolphin and…',
    },
  },
  {
    id: '4',
    date: new Date(),
    unread: true,
    data: {
      id: '1',
      type: 'invoice',
      title: 'New invoice',
      content: 'for February',
    },
  },
  {
    id: '5',
    date: new Date(),
    unread: true,
    data: {
      id: '1',
      type: 'activity_cancel',
      title: 'Activity cancelled',
      content: 'by client Snowmobile day excursion in Tromso with dolphin ',
    },
  },
  {
    id: '6',
    date: new Date(),
    data: {
      id: '1',
      type: 'booking',
      title: 'Reschedule accepted',
      content: 'for Snowmobile day excursion in Tromso with dolphin ',
    },
  },
  {
    id: '7',
    date: new Date(),
    data: {
      id: '1',
      type: 'activity_cancel',
      title: 'Booking request cancelled',
      content: 'for Snowmobile day excursion in Tromso …',
    },
  },
  {
    id: '8',
    date: new Date(),
    data: {
      id: '1',
      type: 'booking',
      title: 'New booking',
      content: 'for Snowmobile day excursion in Tromso with dolphin',
    },
  },
  {
    id: '9',
    date: new Date(),
    data: {
      id: '1',
      type: 'booking',
      title: 'New booking',
      content: 'for Snowmobile day excursion in Tromso with dolphin',
    },
  },
]

export default data
