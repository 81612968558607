import React from 'react'

import moment from 'moment'
import { useHistory, useRouteMatch } from 'react-router-dom'

import Description from '@material-ui/icons/Description'
import Error from '@material-ui/icons/Error'
import InsertInvitation from '@material-ui/icons/InsertInvitation'
import StarRate from '@material-ui/icons/StarRate'

import { BottomContainer, Container, LabelDateTime, TopContainer } from './Row.styled'

import INotification from '../../../../../_types/notification'

import { useTranslation } from 'react-i18next'

interface IProps {
  notification: INotification
}

const icons = {
  booking: {
    Component: InsertInvitation,
    style: {
      color: '#2abd9f',
    },
  },
  review: {
    Component: StarRate,
    style: {
      color: '#ffb725',
    },
  },
  invoice: {
    Component: Description,
    style: {
      color: '#5088c0',
    },
  },
  activity_cancel: {
    Component: Error,
    style: {
      color: '#d0021b',
    },
  },
}

const Row = (props: IProps) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { path } = useRouteMatch()
  const {
    notification: {
      date,
      unread,
      data: { id, type, title, content },
    },
  } = props

  const { Component, style } = icons[type]

  const onClick = () => {
    if (type === 'booking') {
      history.push(`${path}/bookings/view/${id}`)
    }
    console.warn(type, id)
  }

  return (
    <Container onClick={onClick} unread={unread}>
      <TopContainer>
        <strong>{title}</strong> {content}
      </TopContainer>
      <BottomContainer>
        <LabelDateTime>
          <strong>{moment(date).format('L')}</strong>
          {` ${t('layout_MainLayout_NotificationCenter_List_Row_at')} `}
          <strong>{moment(date).format('LT')}</strong>
        </LabelDateTime>
        <Component style={style} />
      </BottomContainer>
    </Container>
  )
}
export default Row
