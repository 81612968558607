import React from 'react'

import { Container } from './Welcome.styled'

import { IUser } from './Welcome.container'

interface IProps {
  user: IUser
}

interface IState {
  opacity: number
  display: boolean
}

class Welcome extends React.Component<IProps, IState> {
  public state = {
    opacity: 0,
    display: true,
  }

  public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
    if (nextProps.user.data !== this.props.user.data && nextProps.user.data.providerInformations) {
      this.setState({ opacity: 1 })
      setTimeout(() => {
        this.setState({ opacity: 0 })
        setTimeout(() => {
          this.setState({ display: false })
        }, 300)
      }, 10000)
    }
  }

  public render() {
    const { opacity, display } = this.state
    const { user } = this.props

    return (
      display && (
        <Container style={{ opacity }}>
          <span role="img" aria-label="Hello">
            👋
          </span>
          {user.data && user.data.providerInformations && user.data.providerInformations.title}
        </Container>
      )
    )
  }
}

export default Welcome
