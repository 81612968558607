export const APP_TOGGLE_MENU = '@@app/TOGGLE_MENU'
export const APP_TOGGLE_NOTIFICATION_CENTER = '@@app/TOGGLE_NOTIFICATION_CENTER'

export interface IToggleMenu {
  type: typeof APP_TOGGLE_MENU
}

export interface IToggleNotificationCenter {
  type: typeof APP_TOGGLE_NOTIFICATION_CENTER
}

export type AppActionTypes = IToggleMenu | IToggleNotificationCenter
