import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 56px;
  font-family: AzoSans;
  display: flex;
  flex-direction: row;
  position: relative;

  z-index: 11;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);

  @media (min-width: 0px) and (orientation: landscape) {
    height: 48px;
  }

  @media (min-width: 600px) {
    height: 64px;
  }
`

// Logo

export const LogoContainer = styled.div`
  height: 100%;
  width: 260px;
  display: flex;
  align-items: center;
  padding: 0 24px 0 45px;
  cursor: pointer;
  user-select: none;
  background-color: #5088c0;
  border-bottom: solid 1px #325e8b;
`

// Toolbar

export const ToolbarContainer = styled.div`
  height: 100%;
  width: calc(100% - 260px);
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #5088c0;
  padding-right: 15px;
  border-bottom: solid 1px rgba(22, 38, 45, 0.4);
`

export const LabelPro = styled.div`
  font-family: AzoSans;
  font-size: 15px;
  font-weight: bold;
  margin-right: 24px;

  @media screen and (max-width: 600px) {
    display: none;
  }
`

export const Badge = styled.div`
  position: absolute;
  background-color: #e24054;
  color: white;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  top: -2px;
  right: -2px;
  font-size: 12px;
  font-weight: 500;
  font-family: AzoSans;
  padding-top: 4px;
`
