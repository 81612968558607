import connect from './connect'
import store from './store'

import * as app from './app/actions'
import * as notification from './notification/actions'
import * as user from './user/actions'

export * from './store'

const actions = {
  app,
  user,
  notification,
}

export { actions, connect, store }
