import React from 'react'

import NotificationsOff from '@material-ui/icons/NotificationsOff'

import { Container, HeaderContainer, HideContainer, LabelRead, LabelTitle } from './NotificationCenter.styled'

import Refresh from '../../../_ui/Refresh'

import { withTranslation, WithTranslation } from 'react-i18next'

import { IActions, INotification } from './NotificationCenter.container'

import List from './List'

interface IProps extends WithTranslation {
  actions: IActions
  notificationCenterOpen: boolean
  notification: INotification
}

class NotificationCenter extends React.Component<IProps> {
  public componentDidMount() {
    const { actions } = this.props
    setTimeout(actions.getNotifications, 10000)
  }

  public render() {
    const {
      notification: { notifications, unread, loading },
      notificationCenterOpen,
      actions: { getNotifications, toggleNotificationCenter },
      t,
    } = this.props

    const empty = {
      Icon: NotificationsOff,
      label: t('layout_MainLayout_NotificationCenter_empty'),
    }

    return (
      <React.Fragment>
        {notificationCenterOpen && <HideContainer onClick={toggleNotificationCenter} />}
        <Container open={notificationCenterOpen} onClick={toggleNotificationCenter}>
          <HeaderContainer>
            <LabelTitle>{t('layout_MainLayout_NotificationCenter_title')}</LabelTitle>
            <LabelRead>
              {unread} {t('layout_MainLayout_NotificationCenter_not_read')}
            </LabelRead>
          </HeaderContainer>
          <Refresh loading={loading} empty={empty} onRefresh={getNotifications}>
            {notifications.length !== 0 ? <List notifications={notifications} /> : undefined}
          </Refresh>
        </Container>
      </React.Fragment>
    )
  }
}

export default withTranslation()(NotificationCenter)
