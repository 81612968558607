import { createStyles, Theme } from '@material-ui/core'

const styles = ({ spacing }: Theme) =>
  createStyles({
    paper: {
      padding: spacing(5),
      width: '300px',
      marginTop: spacing(10),
      marginBottom: spacing(2),
      marginLeft: 'auto',
      marginRight: 'auto',
    },

    loader: {
      margin: '0 90px 20px',
      width: '100%',
    },
    caption: {
      textAlign: 'center',
    },
  })

export default styles
