import React, { useState, useMemo, Suspense, lazy } from 'react'

import AsyncComponentLoader from '../_ui/AsyncComponentLoader'
import AsyncComponentRetry from '../_ui/AsyncComponentRetry'

const LazyLoaderFactory = (promise: () => Promise<{ default: React.ComponentType<any> }>) => {
  const LazyLoader = (props: any) => {
    const [loading, setLoading] = useState<boolean>(true)
    const retry = () => setLoading(true)
    const Lazy = useMemo(
      () =>
        lazy(() =>
          promise().catch(() => {
            setLoading(false)
            // eslint-disable-next-line
            return { default: () => <AsyncComponentRetry retry={retry} /> }
          }),
        ),
      [promise, loading],
    )

    return (
      <Suspense fallback={<AsyncComponentLoader />}>
        <Lazy {...props} />
      </Suspense>
    )
  }

  LazyLoader.displayName = `LazyLoader`

  return LazyLoader
}

export default LazyLoaderFactory
