export const USER_LOGIN_LOADING = '@@user/LOGIN_LOADING'
export const USER_LOGIN_LOADED_SUCCESS = '@@user/LOGIN_LOADED_SUCCESS'
export const USER_LOGIN_LOADED_ERROR = '@@user/LOGIN_LOADED_ERROR'

export const USER_INFOS_LOADING = '@@user/INFOS_LOADING'
export const USER_INFOS_LOADED_SUCCESS = '@@user/INFOS_LOADED_SUCCESS'
export const USER_INFOS_LOADED_ERROR = '@@user/INFOS_LOADED_ERROR'

export const USER_SIGN_OUT = '@@user/SIGN_OUT'

export interface ILoginLoadingAction {
  type: typeof USER_LOGIN_LOADING
}

export interface ILoginLoadedSuccessAction {
  type: typeof USER_LOGIN_LOADED_SUCCESS
}

export interface ILoginLoadedErrorAction {
  type: typeof USER_LOGIN_LOADED_ERROR
  errorCode: number
}

export interface IInfosLoadingAction {
  type: typeof USER_INFOS_LOADING
}

export interface IInfosLoadedSuccessAction {
  type: typeof USER_INFOS_LOADED_SUCCESS
  data: {}
}

export interface IInfosLoadedErrorAction {
  type: typeof USER_INFOS_LOADED_ERROR
  errorCode: number
}

export interface ISignOutAction {
  type: typeof USER_SIGN_OUT
}

export type UserActionTypes =
  | ILoginLoadingAction
  | ILoginLoadedSuccessAction
  | ILoginLoadedErrorAction
  | IInfosLoadingAction
  | IInfosLoadedSuccessAction
  | IInfosLoadedErrorAction
  | ISignOutAction
