import { ThunkAction } from 'redux-thunk'
import { AppState } from '../'
import {
  IInfosLoadedErrorAction,
  IInfosLoadedSuccessAction,
  ILoginLoadedErrorAction,
  ILoginLoadedSuccessAction,
  USER_INFOS_LOADED_ERROR,
  USER_INFOS_LOADED_SUCCESS,
  USER_INFOS_LOADING,
  USER_LOGIN_LOADED_ERROR,
  USER_LOGIN_LOADED_SUCCESS,
  USER_LOGIN_LOADING,
  USER_SIGN_OUT,
  UserActionTypes,
} from './actionTypes'

import { IParams, rest, restWithToken } from '../../_utils/rest'
import { URL_GET_USER, URL_POST_LOGIN } from './urls'

import { AuthCookieService } from '../../_utils/auth'

type Thunk<R> = ThunkAction<R, AppState, null, UserActionTypes>

const infosSuccess = (data: {}): IInfosLoadedSuccessAction => ({
  type: USER_INFOS_LOADED_SUCCESS,
  data,
})

const infosError = (errorCode: number): IInfosLoadedErrorAction => ({
  type: USER_INFOS_LOADED_ERROR,
  errorCode,
})

export const infos = (): Thunk<void> => async dispatch => {
  dispatch({
    type: USER_INFOS_LOADING,
  })

  try {
    const params: IParams = {
      url: URL_GET_USER,
    }

    const res = await restWithToken(params)
    const json = await res.json()
    if (res.status === 200) {
      dispatch(infosSuccess(json))
    } else {
      dispatch(infosError(json.code))
    }
  } catch (error) {
    console.error(error)
    dispatch(infosError(-1))
  }
}

const loginSuccess = (): ILoginLoadedSuccessAction => ({
  type: USER_LOGIN_LOADED_SUCCESS,
})

const loginError = (errorCode: number): ILoginLoadedErrorAction => ({
  type: USER_LOGIN_LOADED_ERROR,
  errorCode,
})

export const login = (username: string, password: string, persistent: boolean): Thunk<void> => async dispatch => {
  dispatch({
    type: USER_LOGIN_LOADING,
  })

  try {
    const basicAuth = btoa(`${username}:${password}`)

    const params: IParams = {
      url: URL_POST_LOGIN,
      method: 'POST',
      headers: { Authorization: `Basic ${basicAuth}` },
    }

    const res = await rest(params)
    if (res.status === 200) {
      const { token } = await res.json()
      AuthCookieService.storeAuthInfo(token, persistent)
      dispatch(loginSuccess())
      dispatch(infos())
    } else {
      const { code } = await res.json()
      dispatch(loginError(code))
    }
  } catch (error) {
    console.error(error)
    dispatch(loginError(-1))
  }
}

export const signOut = (): Thunk<void> => async dispatch => {
  AuthCookieService.clearAuthInfo()
  dispatch({
    type: USER_SIGN_OUT,
  })
}
