import React from 'react'

import getDisplayName from '../getDisplayName'

import { connect } from '../../_redux'
import { IUserState } from '../../_redux/user/reducers'

interface IProps {
  user: IUserState
}

const withGuard = (roles: string[]) => (WrappedComponent: React.ComponentType<any>) => {
  const WithGuard = (props: IProps) => {
    const {
      user: { data },
    } = props

    const hasRoles = () => {
      if (data.roles) {
        const authorized = roles.reduce((acc, role) => {
          if (data.roles && data.roles.indexOf(role) !== -1) return true
          return acc
        }, false)
        return authorized
      }

      return false
    }

    return (
      /**
       * What we do here:
       * - Use Route to get information about the original route
       * - Display original component if auth is correct, else redirect to login with original route sent as prop
       */
      (data.roles && hasRoles() && <WrappedComponent {...props} />) || null
    )
  }

  WithGuard.displayName = `WithGuard(${getDisplayName(WrappedComponent)})`

  return connect(state => ({
    user: state.user,
  }))(WithGuard)
}

export default withGuard
