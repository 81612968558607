import SideBar from './SideBar.component'

import { connect, actions, AppState } from '../../../_redux'

export interface IActions {
  toggleMenu: typeof actions.app.toggleMenu
}

export type IUser = AppState['user']

export default connect(
  state => ({
    menuOpen: state.app.openMenu,
    user: state.user,
  }),
  {
    toggleMenu: actions.app.toggleMenu,
  },
)(SideBar)
