import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 190px;
  align-items: center;
  color: #e24054;
  justify-content: space-between;
`

export const Label = styled.div`
  height: 27px;
  font-weight: 500;
  color: rgba(22, 38, 45, 0.7);
  text-align: center;
`
