import React from 'react'

import { Container } from './List.styled'

import Row from './Row'

import INotification from '../../../../_types/notification'

interface IProps {
  notifications: INotification[]
}

const List = (props: IProps) => {
  const { notifications } = props

  return (
    <Container>
      {notifications.map(notification => (
        <Row key={notification.id} notification={notification} />
      ))}
    </Container>
  )
}
export default List
