import styled from 'styled-components'

export const Container = styled.div<{ unread?: boolean }>`
  min-height: 96px;
  max-height: 127px;
  border-bottom: solid 1px rgba(22, 38, 45, 0.2);
  padding: 15px 20px;
  font-family: AzoSans;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  background-color: ${({ unread }) => (unread ? '#e9eff6' : 'white')};
`

export const TopContainer = styled.div`
  max-height: 46px;
  font-size: 18px;
  font-weight: 300;
  color: rgba(22, 38, 45, 0.7);
  margin-bottom: 18px;
`

export const BottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const LabelDateTime = styled.div`
  font-size: 18px;
  font-weight: 300;
  color: rgba(22, 38, 45, 0.7);
`
