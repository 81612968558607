import styled from 'styled-components'

export const Container = styled.div`
  position: absolute;
  top: 31px;
  z-index: 12;
  left: 290px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 62px;
  border-radius: 5px;
  border: solid 1px #5088c0;
  background-color: #dae8f7;
  transition: opacity 0.3s, left 0.3s;
  font-family: AzoSans;
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  color: #3f88c5;
  padding: 0 20px;

  @media screen and (max-width: 1100px) {
    left: 10px;
    top: 73px;
  }

  @media screen and (max-width: 600px) {
    top: 65px;
  }
`
