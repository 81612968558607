import { ThunkAction } from 'redux-thunk'
import { AppState } from '../'
import {
  IGetLoadedErrorAction,
  IGetLoadedSuccessAction,
  NotificationActionTypes,
  NOTIFICATIONS_GET_LOADED_ERROR,
  NOTIFICATIONS_GET_LOADED_SUCCESS,
  NOTIFICATIONS_GET_LOADING,
} from './actionTypes'

import INotification from '../../_types/notification'

// import { IParams, restWithToken } from '../../_utils/rest'
// import { URL_GET_NOTIFICATIONS } from './urls'

import data from './mock_data'

type Thunk<R> = ThunkAction<R, AppState, null, NotificationActionTypes>

const getSuccess = (notifications: INotification[], unread: number): IGetLoadedSuccessAction => ({
  type: NOTIFICATIONS_GET_LOADED_SUCCESS,
  notifications,
  unread,
})

const getError = (): IGetLoadedErrorAction => ({
  type: NOTIFICATIONS_GET_LOADED_ERROR,
})

export const getNotifications = (): Thunk<void> => async dispatch => {
  dispatch({
    type: NOTIFICATIONS_GET_LOADING,
  })

  try {
    /*
    const params: IParams = {
      url: URL_GET_NOTIFICATIONS,
    }

    const res = await restWithToken(params)
    if (res.status === 200) {
      const { data } = await res.json()
      dispatch(getSuccess(data))
    } else {
      dispatch(getError())
    }
    */

    setTimeout(() => {
      dispatch(getSuccess(data, 3))
    }, 3000)
  } catch (error) {
    console.error(error)
    dispatch(getError())
  }
}
