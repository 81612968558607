import styled from 'styled-components'

/*
  z-index for Notification center is 10
*/

export const Container = styled.div<{ open: boolean }>`
  z-index: 10;
  font-family: AzoSans;
  position: absolute;
  top: 0;
  right: ${({ open }) => (open ? 0 : -422)}px;
  transition: right 0.3s;
  width: 412px;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.5);
`

export const HideContainer = styled.div`
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100vw;
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 58px;
  border-bottom: solid 1px rgba(22, 38, 45, 0.2);
  padding: 2px 20px 0 20px;
  justify-content: space-between;
`

export const LabelTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
  color: #5088c0;
`

export const LabelRead = styled.div`
  font-size: 20px;
  font-weight: 300;
  color: #5088c0;
`
