import Header from './Header.component'

import { actions, connect, AppState } from '../../../_redux'

export interface IActions {
  signOut: typeof actions.user.signOut
  toggleMenu: typeof actions.app.toggleMenu
  toggleNotificationCenter: typeof actions.app.toggleNotificationCenter
}

export type IUser = AppState['user']

export default connect(
  state => ({
    notificationCenterOpen: state.app.openNotificationCenter,
    user: state.user,
    unread: state.notification.unread,
  }),
  {
    signOut: actions.user.signOut,
    toggleMenu: actions.app.toggleMenu,
    toggleNotificationCenter: actions.app.toggleNotificationCenter,
  },
)(Header)
