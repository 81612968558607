import React from 'react'
import { Button, Paper, Typography, WithStyles } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import OfflineIcon from '@material-ui/icons/CloudOff'

import styles from './AsyncComponentRetry.styles'

interface IProps extends WithStyles<typeof styles> {
  retry: () => void
}

const AsyncComponentRetry = (props: IProps) => {
  const { classes, retry } = props

  return (
    <Paper className={classes.paper}>
      <Typography className={classes.offline}>
        <OfflineIcon fontSize="inherit" color="disabled" />
      </Typography>
      <Typography component="p" variant="body1" className={classes.caption}>
        <React.Fragment>
          Network error.
          <br />
          Please check your connection.
        </React.Fragment>
      </Typography>
      <Typography component="p" variant="body1" className={classes.retry}>
        <Button variant="outlined" color="primary" onClick={retry}>
          Retry
        </Button>
      </Typography>
    </Paper>
  )
}

export default withStyles(styles)(AsyncComponentRetry)
