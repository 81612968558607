import Toast from './Toast.container'
import { IToast } from './Toast.component'

export type IToastArgs = IToast

export const dispatchToast = (detail: IToast) => {
  const event = new CustomEvent('toast', { detail })
  document.dispatchEvent(event)
}

export default Toast
