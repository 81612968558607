import React from 'react'

import AuthCookieService from './AuthCookieService'

import { IActions } from './Auth.container'

interface IProps {
  children: React.ReactNode
  actions: IActions
}

class Auth extends React.Component<IProps> {
  public componentDidMount() {
    const authInfos = AuthCookieService.getAuthInfo()
    if (authInfos && authInfos.token) {
      const { actions } = this.props
      actions.infos()
    }
  }

  public render() {
    const { children } = this.props
    return children
  }
}

export default Auth
