import NotificationCenter from './NotificationCenter.component'

import { actions, AppState, connect } from '../../../_redux'

export type INotification = AppState['notification']

export interface IActions {
  getNotifications: typeof actions.notification.getNotifications
  toggleNotificationCenter: typeof actions.app.toggleNotificationCenter
}

export default connect(
  state => ({
    notification: state.notification,
    notificationCenterOpen: state.app.openNotificationCenter,
  }),
  {
    getNotifications: actions.notification.getNotifications,
    toggleNotificationCenter: actions.app.toggleNotificationCenter,
  },
)(NotificationCenter)
