import React from 'react'
import { Link } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import { WithStyles } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import styles from './Error403.styles'

import { useTranslation } from 'react-i18next'

type IProps = WithStyles<typeof styles>

const Error403 = (props: IProps) => {
  const { t } = useTranslation()
  const { classes } = props

  return (
    <Paper className={classes.paper404}>
      <Typography component="h1" variant="h2" gutterBottom>
        {t('Error403.title')}
      </Typography>
      <Typography component="h2" variant="h5">
        {t('Error403.subtitle')}
      </Typography>
      <Typography component="p" variant="body2" gutterBottom>
        {t('Error403.errorMessage')}
      </Typography>
      <div className={classes.buttonContainer}>
        <Button variant="contained" color="primary" component={(buttonProps: any) => <Link {...buttonProps} to="/" />}>
          {t('backToHomepage')}
        </Button>
      </div>
    </Paper>
  )
}

export default withStyles(styles)(Error403)
