import { SvgIconTypeMap } from '@material-ui/core'
import { OverridableComponent } from '@material-ui/core/OverridableComponent'
import { IUser } from './SideBar.container'

import roles from '../../../_utils/auth/roles'

import CalendarToday from '@material-ui/icons/CalendarTodayRounded'
import DashboardIcon from '@material-ui/icons/DashboardRounded'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCartRounded'
import StarBorder from '@material-ui/icons/StarRounded'
import Receipt from '@material-ui/icons/ReceiptRounded'
import AssignmentIcon from '@material-ui/icons/Assignment'
import ContactSupportIcon from '@material-ui/icons/ContactSupport'
import LinkIcon from '@material-ui/icons/Link'
import AccountBoxIcon from '@material-ui/icons/AccountBox'
import ShareIcon from '@material-ui/icons/Share'
import AddIcon from '@material-ui/icons/AddCircle'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import SchoolIcon from '@material-ui/icons/School'

import { TFunction } from 'i18next'

export interface IItem {
  title: string
  path: string
  Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>
  iconStyle?: {}
  isLink?: boolean
}

const getItems = (user: IUser, t: TFunction, i18n: any, displayFakeProviderSidebar: boolean): IItem[] => {
  const language = i18n.language.substring(0, 2)

  if (user.data.roles?.includes(roles.PROVIDER) || displayFakeProviderSidebar) {
    return [
      {
        title: t('layout_MainLayout_SideBar_dashboard'),
        path: `/provider/dashboard`,
        Icon: DashboardIcon,
      },
      {
        title: t('layout_MainLayout_SideBar_bookings_list'),
        path: `/provider/bookings/list`,
        Icon: ShoppingCartIcon,
      },
      {
        title: t('layout_MainLayout_SideBar_bookings_calendar'),
        path: `/provider/bookings/calendar`,
        Icon: CalendarToday,
      },
      /* disabled as we don’t want the providers to access this page for now.
      {
        title: t('layout_MainLayout_SideBar_activities_list'),
        path: `/activities/list`,
        Icon: NaturePeople,
      },*/
      {
        title: t('layout_MainLayout_SideBar_reviews_list'),
        path: `/provider/reviews/list`,
        Icon: StarBorder,
        iconStyle: { fontSize: 30 },
      },
      {
        title: t('layout_MainLayout_SideBar_invoices_list'),
        path: `/provider/invoices/list`,
        Icon: Receipt,
      },
      /*{
        title: 'Promotions', //t('layout_MainLayout_SideBar_invoices_list'),
        path: `/promotions/list`,
        Icon: Receipt,
      },*/
      {
        title: t('layout_MainLayout_SideBar_misc_addactivity'),
        path: `/provider/misc/add-activity`,
        Icon: AddIcon,
      },
      {
        title: t('layout_MainLayout_SideBar_misc_payments'),
        path: `/provider/misc/payments`,
        Icon: AccountBalanceIcon,
      },
      {
        title: t('layout_MainLayout_SideBar_misc_contact'),
        path: `/provider/misc/contact`,
        Icon: ContactSupportIcon,
      },
      {
        title: t('layout_MainLayout_SideBar_misc_recommend'),
        path: `/provider/misc/recommend`,
        Icon: ShareIcon,
      },
      {
        title: t('layout_MainLayout_SideBar_misc_blog'),
        path: `https://www.manawa.com/${language}/partners/articles`,
        Icon: SchoolIcon,
        isLink: true,
      },
    ]
  }

  if (user.data.roles?.includes(roles.AFFILIATE)) {
    const items = [
      {
        title: t('layout_MainLayout_SideBar_dashboard'),
        path: `/affiliation/dashboard`,
        Icon: DashboardIcon,
      },
      {
        title: t('layout_MainLayout_SideBar_profile'),
        path: `/affiliation/profile`,
        Icon: AccountBoxIcon,
      },
      {
        title: t('layout_MainLayout_SideBar_tracking_links'),
        path: `/affiliation/campaigns`,
        Icon: LinkIcon,
      },
      {
        title: t('layout_MainLayout_SideBar_faq'),
        path: `/affiliation/faq`,
        Icon: ContactSupportIcon,
      },
    ]
    if (user.data.partnerInformations?.accessApiAuthorized) {
      items.push({
        title: t('layout_MainLayout_SideBar_apiDoc'),
        path: `/affiliation/api-doc`,
        Icon: AssignmentIcon,
      })
    }

    return items
  }

  return []
}

export default getItems
