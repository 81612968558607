import React, { Suspense } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import { Provider } from 'react-redux'
//import Routing from '../../routing'

import { Auth } from '../../_utils/auth'

import { store } from '../../_redux'

import ErrorBoundary from './ErrorBoundary/ErrorBoundary.component'
import withTheme from './withTheme.component'

import AsAsync from '../../_utils/AsAsync.component'

import MainLayout from '../MainLayout'

import { useTranslation } from 'react-i18next'
import Loader from '../../_ui/Loader'

const AsyncAuth = AsAsync(() => import('../../_modules/auth'))

const App = () => {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <InnerApp />
      </Suspense>
    </ErrorBoundary>
  )
}

const InnerApp = () => {
  useTranslation()

  return (
    <Provider store={store}>
      <Auth>
        <Router>
          <Switch>
            <Route path="/auth" component={AsyncAuth} />
            <Route path="/" component={MainLayout} />
          </Switch>
        </Router>
      </Auth>
    </Provider>
  )
}

export default withTheme(App)
