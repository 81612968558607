import React from 'react'
import { Link } from 'react-router-dom'

import SignInIcon from '@material-ui/icons/Lock'
import Notifications from '@material-ui/icons/Notifications'
import NotificationsNone from '@material-ui/icons/NotificationsNone'
import SignOutIcon from '@material-ui/icons/PowerSettingsNew'
import HelpIcon from '@material-ui/icons/HelpOutline'
import { IconButton, Tooltip } from '@material-ui/core'

import { ReactComponent as Logo } from '../../../_assets/header-logo.svg'

import { IActions, IUser } from './Header.container'

import { useTranslation } from 'react-i18next'

import { Container, LabelPro, LogoContainer, ToolbarContainer, Badge } from './Header.styled'
import roles from '../../../_utils/auth/roles'
import LanguageSwitcher from '../../../_ui/LanguageSwitcher/LanguageSwitcher.component'

interface IProps {
  unread: number
  user: IUser
  notificationCenterOpen: boolean
  actions: IActions
}

const Header = (props: IProps) => {
  const { t, i18n } = useTranslation()

  const {
    user: { loading, data },
    unread,
    notificationCenterOpen,
    actions: { signOut, toggleMenu, toggleNotificationCenter },
  } = props

  const userLogged = () => Object.keys(data).length !== 0

  return (
    <Container>
      <LogoContainer onClick={toggleMenu}>
        <Logo />
      </LogoContainer>
      <ToolbarContainer>
        {userLogged() ? (
          <React.Fragment>
            {process.env.REACT_APP_NOTIFICATION_CENTER === 'true' && (
              <IconButton
                style={{ margin: '0 10px', position: 'relative' }}
                color="inherit"
                aria-label="Open notification center"
                onClick={toggleNotificationCenter}
              >
                {notificationCenterOpen ? <Notifications /> : <NotificationsNone />}
                {unread !== 0 && <Badge>{unread}</Badge>}
              </IconButton>
            )}
            <LabelPro>{(data.providerInformations && data.providerInformations.title) || data.email}</LabelPro>

            {data.roles?.includes(roles.PROVIDER) && (
              <Tooltip title="User guide">
                <IconButton color="inherit" href={`/backoffice-guide-${i18n.language}.pdf`} aria-label="User guide">
                  <HelpIcon />
                </IconButton>
              </Tooltip>
            )}

            <LanguageSwitcher iconOnly />

            <Tooltip title="Sign out">
              <IconButton color="inherit" onClick={signOut}>
                <SignOutIcon />
              </IconButton>
            </Tooltip>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <LabelPro>
              {loading === 0 && t('layout_MainLayout_Header_not_logged')}
              {loading === 1 && t('layout_MainLayout_Header_loading')}
              {loading === -1 && t('layout_MainLayout_Header_user_error')}
            </LabelPro>
            {loading !== 1 && (
              <IconButton color="inherit" component={(props: any) => <Link {...props} to="/auth/sign-in" />}>
                <SignInIcon />
              </IconButton>
            )}
          </React.Fragment>
        )}
      </ToolbarContainer>
    </Container>
  )
}

export default Header
