import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import thunk from 'redux-thunk'

import app from './app/reducers'
import notification from './notification/reducers'
import user from './user/reducers'

const defaultReducer = {
  app,
  user,
  notification,
}

export const lazyLoadedReducers: any = {
  ...defaultReducer,
}

const rootReducer = combineReducers(defaultReducer)

const REDUX_DEVTOOLS = typeof window !== 'undefined' && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__

const composeEnhancers = REDUX_DEVTOOLS || compose

const configureStore = () => createStore(rootReducer, {}, composeEnhancers(applyMiddleware(thunk)))

export type AppState = ReturnType<typeof rootReducer>

export default configureStore()
