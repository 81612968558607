import React from 'react'

import LanguageIcon from '@material-ui/icons/Language'
import { Button, IconButton, Menu, MenuItem, Tooltip } from '@material-ui/core'

import { useTranslation } from 'react-i18next'
import { availableLocales } from '../../_utils/i18n'

interface IProps {
  iconOnly?: boolean
}

const LanguageSwitcher = ({ iconOnly }: IProps) => {
  const { t, i18n } = useTranslation('common')
  const [languageMenuAnchorEl, setLanguageMenuAnchorEl] = React.useState<null | HTMLElement>(null)
  const [selectedLanguage, setSelectedLanguage] = React.useState(i18n.language)

  // Handle language menu
  const handleLanguageMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setLanguageMenuAnchorEl(event.currentTarget)
  }
  const handleLanguageMenuClose = () => {
    setLanguageMenuAnchorEl(null)
  }
  const handleLanguageMenuChangeLang = (lang: string) => {
    i18n.changeLanguage(lang)
    setSelectedLanguage(lang)
    setLanguageMenuAnchorEl(null)
  }

  return (
    <>
      {iconOnly && (
        <Tooltip title={t('LanguageSwitcher.label') || ''}>
          <IconButton
            aria-controls="language-switcher"
            aria-haspopup="true"
            color="inherit"
            onClick={handleLanguageMenuClick}
          >
            <LanguageIcon />
          </IconButton>
        </Tooltip>
      )}
      {!iconOnly && (
        <Button
          aria-controls="language-switcher"
          aria-haspopup="true"
          color="inherit"
          onClick={handleLanguageMenuClick}
          startIcon={<LanguageIcon />}
        >
          {t('LanguageSwitcher.label')}
        </Button>
      )}

      <Menu
        id="language-switcher"
        anchorEl={languageMenuAnchorEl}
        keepMounted
        open={Boolean(languageMenuAnchorEl)}
        onClose={handleLanguageMenuClose}
      >
        {Object.getOwnPropertyNames(availableLocales).map(locale => (
          <MenuItem
            key={locale}
            onClick={() => handleLanguageMenuChangeLang(locale)}
            selected={locale === selectedLanguage}
          >
            {(availableLocales as any)[locale].name}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default LanguageSwitcher
