import React, { ErrorInfo, ReactNode } from 'react'

import Error500 from '../../Error500'

interface IProps {
  children?: ReactNode
}

interface IState {
  error?: Error
  errorInfo?: ErrorInfo
}
class ErrorBoundary extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {}
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      error,
      errorInfo,
    })

    // TODO send error to error reporting service
  }

  public render() {
    const { children } = this.props
    const { errorInfo } = this.state

    return errorInfo ? <Error500 /> : children
  }
}

export default ErrorBoundary
