import Welcome from './Welcome.component'

import { connect, AppState } from '../../../_redux'

export type IUser = AppState['user']

export default connect(
  state => ({
    user: state.user,
  }),
  undefined,
)(Welcome)
