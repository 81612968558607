import React from 'react'

import { Button, Paper, Typography } from '@material-ui/core'
import { styled } from '@material-ui/core/styles'
import { useTranslation, Trans } from 'react-i18next'

const PaperCentered = styled(Paper)(({ theme }) => ({
  margin: '10vh 20vw',
  padding: theme.spacing(6),
}))

const ButtonContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(3),
}))

const Error500 = () => {
  const { t } = useTranslation()

  const refresh = () => {
    window.location.reload()
  }
  const homepage = () => {
    window.location.href = '/'
  }

  return (
    <PaperCentered>
      <Typography component="h1" variant="h2" gutterBottom>
        <span role="img" aria-label="">
          🙀
        </span>{' '}
        {t('Error500.title')}
      </Typography>
      <Typography component="h2" variant="h5">
        {t('Error500.subtitle')}
      </Typography>
      <Typography component="p" variant="body2" gutterBottom>
        {t('Error500.errorMessage')}
      </Typography>

      <Typography component="p" variant="body2" gutterBottom>
        <Trans i18nKey="Error500.contactUs">
          <span>Contact us </span>
          <a href="mailto:support@manawa.com">Click here</a>.
        </Trans>
      </Typography>
      <ButtonContainer>
        <Button variant="contained" color="primary" onClick={refresh}>
          {t('refreshThePage')}
        </Button>{' '}
        <Button variant="contained" color="primary" onClick={homepage}>
          {t('backToHomepage')}
        </Button>
      </ButtonContainer>
    </PaperCentered>
  )
}

export default Error500
