import React from 'react'

import { CircularProgress, Paper, Typography, WithStyles } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import styles from './AsyncComponentLoader.styles'

const AsyncComponentLoader = (props: WithStyles<typeof styles>) => {
  const { classes } = props

  return (
    <Paper className={classes.paper}>
      <CircularProgress className={classes.loader} />{' '}
      <Typography component="p" variant="body1" className={classes.caption}>
        Loading
      </Typography>
    </Paper>
  )
}

export default withStyles(styles)(AsyncComponentLoader)
