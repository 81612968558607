import { createStyles, Theme } from '@material-ui/core'

const styles = ({ spacing }: Theme) =>
  createStyles({
    paper404: {
      margin: '10vh 20vw',
      padding: spacing(6),
    },

    buttonContainer: {
      marginTop: spacing(3),
    },
  })

export default styles
