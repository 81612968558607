import React, { ReactNode } from 'react'

import CircularProgress from '@material-ui/core/CircularProgress'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import { WithStyles } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import styles from './Loader.styles'

interface IProps extends WithStyles<typeof styles> {
  children?: ReactNode
}

const Loader = (props: IProps) => {
  const { classes } = props
  let { children } = props

  if (!children) {
    children = <React.Fragment>Loading</React.Fragment>
  }

  return (
    <Paper className={classes.paper}>
      <CircularProgress className={classes.loader} />
      <Typography component="p" variant="body1" className={classes.caption}>
        {children}
      </Typography>
    </Paper>
  )
}

export default withStyles(styles)(Loader)
