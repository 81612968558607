import React from 'react'
import { Redirect, RouteComponentProps } from 'react-router-dom'

import Loader from '../../_ui/Loader'

import Error403 from '../../_layout/Error403'
import getDisplayName from '../getDisplayName'

import { connect } from '../../_redux'
import { IUserState } from '../../_redux/user/reducers'

interface IProps extends RouteComponentProps {
  user: IUserState
}

const withAuthGuard = (roles: string[]) => (WrappedComponent: React.ComponentType<any>) => {
  const WithAuthGuard = (props: IProps) => {
    const {
      user: { data, loading },
      location,
    } = props

    const hasRoles = () => {
      if (data.roles) {
        const authorized = roles.reduce((acc, role) => {
          if (data.roles && data.roles.indexOf(role) !== -1) return true
          return acc
        }, false)
        return authorized
      }

      return false
    }

    return (
      /**
       * What we do here:
       * - Use Route to get information about the original route
       * - Display original component if auth is correct, else redirect to login with original route sent as prop
       */
      <React.Fragment>
        {loading === 1 ? (
          <Loader />
        ) : data.roles ? (
          hasRoles() ? (
            <WrappedComponent {...props} />
          ) : (
            <Error403 />
          )
        ) : (
          <Redirect
            to={{
              pathname: `/auth/sign-in`,
              state: { from: location },
            }}
          />
        )}
      </React.Fragment>
    )
  }

  WithAuthGuard.displayName = `WithAuthGuard(${getDisplayName(WrappedComponent)})`

  return connect(state => ({
    user: state.user,
  }))(WithAuthGuard)
}

export default withAuthGuard
