import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import qs from 'query-string'

import Loader from '../../_ui/Loader'

import { connect, actions } from '../../_redux'
import { IUserState } from '../../_redux/user/reducers'

import ROLES from './roles'

interface IProps extends RouteComponentProps {
  user: IUserState
  actions: {
    logout: typeof actions.user.signOut
  }
}

interface IState {
  loading: boolean
}

const ORIGINS = {
  BO: 'bo',
}

const withBOAdminGuard = () => (WrappedComponent: React.ComponentType<any>) => {
  class WithBOAdminGuard extends React.Component<IProps, IState> {
    public state = {
      loading: true,
    }

    public componentDidMount() {
      this.isComingFromBOProp()
    }

    public isComingFromBOProp = () => {
      const {
        user: { data },
        location,
        history: { push },
        actions: { logout },
      } = this.props

      const { origin } = qs.parse(location.search)
      if (origin === ORIGINS.BO && data.roles) {
        const authorized = [ROLES.INTERNAL_USER].reduce((acc, role) => {
          if (data.roles && data.roles.indexOf(role) !== -1) return true
          return acc
        }, false)

        const { pathname } = location
        push(pathname)

        if (authorized) {
          this.setState({ loading: false })
        } else {
          logout()
        }
      } else {
        this.setState({ loading: false })
      }
    }

    public render() {
      const { loading } = this.state

      return (
        /**
         * What we do here:
         * - Use Route to get information about the original route
         * - Display original component if auth is correct, else redirect to login with original route sent as prop
         */
        <React.Fragment>{loading ? <Loader /> : <WrappedComponent {...this.props} />}</React.Fragment>
      )
    }
  }

  return connect(
    state => ({
      user: state.user,
    }),
    {
      logout: actions.user.signOut,
    },
  )(WithBOAdminGuard)
}

export default withBOAdminGuard
