import React from 'react'

import { Container, Label } from './Empty.styled'

interface IProps {
  Icon?: React.ComponentType<any>
  label: string
  small?: boolean
}

const Empty = (props: IProps) => {
  const { Icon, label, small } = props
  return (
    <Container>
      {Icon && <Icon style={{ fontSize: small ? 70 : 127 }} />}
      <Label style={{ fontSize: small ? 16 : 20 }}>{label}</Label>
    </Container>
  )
}

export default Empty
