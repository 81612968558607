import React from 'react'

import CssBaseline from '@material-ui/core/CssBaseline'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'

import getDisplayName from '../../_utils/getDisplayName'

// Our theme, see
// tslint:disable-next-line max-line-length
// https://material.io/tools/color/#!/?view.left=0&view.right=1&primary.color=5088C0&primary.text.color=ffffff&secondary.color=E24054&secondary.text.color=ffffff
const theme = createMuiTheme({
  palette: {
    primary: {
      contrastText: '#fff',
      dark: '#105b8f',
      light: '#EFF6FD',
      main: '#5088c0',
    },
    secondary: {
      contrastText: '#fff',
      dark: '#aa002c',
      light: '#ff7580',
      main: '#e24054',
    },
  },
  typography: {
    fontFamily: ['AzoSans', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  },

  // Global CSS overrides
  overrides: {
    MuiCssBaseline: {
      '@global': {
        code: {
          display: 'inline-block',
          color: '#e24054',
          backgroundColor: '#f0f0f0',
          padding: '1px 4px',
          borderRadius: '4px',
        },
      },
    },
  },
})

const withTheme = <P extends {}>(WrappedComponent: React.ComponentType<P>) =>
  class WithRoot extends React.Component<P> {
    public displayName = `WithRoot(${getDisplayName(WrappedComponent)})`

    public render = () => (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <WrappedComponent {...this.props} />
      </MuiThemeProvider>
    )
  }

export default withTheme
