import INotification from '../../_types/notification'

export const NOTIFICATIONS_GET_LOADING = '@@notification/GET_LOADING'
export const NOTIFICATIONS_GET_LOADED_SUCCESS = '@@otification/GET_LOADED_SUCCESS'
export const NOTIFICATIONS_GET_LOADED_ERROR = '@@otification/GET_LOADED_ERROR'

export interface IGetLoadingAction {
  type: typeof NOTIFICATIONS_GET_LOADING
}

export interface IGetLoadedSuccessAction {
  type: typeof NOTIFICATIONS_GET_LOADED_SUCCESS
  notifications: INotification[]
  unread: number
}

export interface IGetLoadedErrorAction {
  type: typeof NOTIFICATIONS_GET_LOADED_ERROR
}

export type NotificationActionTypes = IGetLoadingAction | IGetLoadedSuccessAction | IGetLoadedErrorAction
