import React from 'react'

import { Container, IconContainer } from './Item.styled'

import { SvgIconProps } from '@material-ui/core/SvgIcon'

interface IProps {
  Icon: React.ComponentType<SvgIconProps>
  title: string
  selected: boolean
  onClick: () => void
  iconStyle?: {}
  hideOnMobile?: boolean
}

class Item extends React.Component<IProps> {
  public state = {
    hover: false,
  }

  public toggleHover = () => {
    const { hover } = this.state
    this.setState({ hover: !hover })
  }

  public render() {
    const { Icon, title, onClick, selected, iconStyle, hideOnMobile } = this.props
    const { hover } = this.state

    return (
      <Container
        selected={selected}
        hover={hover}
        onClick={onClick}
        onMouseEnter={this.toggleHover}
        onMouseLeave={this.toggleHover}
        hideOnMobile={hideOnMobile}
      >
        <IconContainer>
          <Icon style={iconStyle} />
        </IconContainer>
        {title}
      </Container>
    )
  }
}

export default Item
