import styled from 'styled-components'

export const Container = styled.div<{ open: boolean }>`
  overflow: hidden;
  height: 100%;
  background-color: #16262d;
  transition: width 0.3s, min-width 0.3s, left 0.3s;
  width: ${({ open }) => (open ? 260 : 0)}px;
  min-width: ${({ open }) => (open ? 260 : 0)}px;

  @media screen and (max-width: 1100px) {
    position: absolute;
    z-index: 2;
    left: ${({ open }) => (open ? 0 : -260)}px;
    width: 260px;
    min-width: 260px;
  }
`

export const ListContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  width: 260px;
`

export const LoginTeaser = styled.div`
  border: 1px solid #274d5c;
  background-color: #1e343d;
  border-radius: 4px;
  padding: 4px 4px 4px 32px;
  font-size: 12px;
  color: #999;
  position: relative;
  margin-top: 8px;
  cursor: pointer;
`

export const LoginTeaserIcon = styled.div`
  position: absolute;
  top: 4px;
  left: 4px;
`

export const HideContainer = styled.div`
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100vw;
`
