export default {
  INTERNAL_REGIONAL_MANAGER: 'ROLE_INTERNAL_REGIONAL_MANAGER',
  INTERNAL_CUSTOMER_SUPPORT: 'ROLE_INTERNAL_CUSTOMER_SUPPORT',
  INTERNAL_TECH: 'ROLE_INTERNAL_TECH',
  INTERNAL_FINANCE: 'ROLE_INTERNAL_FINANCE',
  INTERNAL_SHAREHOLDER: 'ROLE_INTERNAL_SHAREHOLDER',
  PROVIDER: 'ROLE_PROVIDER',
  AFFILIATE: 'ROLE_AFFILIATE',
  INTERNAL_USER: 'ROLE_INTERNAL_USER',

  API_ACCESS: 'ROLE_API_ACCESS',
  API_PROVIDER: 'ROLE_API_PROVIDER',
  PRICING_EDIT_OWN: 'ROLE_PRICING_EDIT_OWN',
  BOOKING_VIEW_OWN_PROVIDER: 'ROLE_BOOKING_VIEW_OWN_PROVIDER',
  PROVIDER_SOURCE: 'ROLE_PROVIDER_SOURCE',
  USER_VIEW: 'ROLE_USER_VIEW',
  USER_EDIT: 'ROLE_USER_EDIT',
  PROVIDER_VIEW: 'ROLE_PROVIDER_VIEW',
  PROVIDER_EDIT: 'ROLE_PROVIDER_EDIT',
  ACTIVITY_VIEW: 'ROLE_ACTIVITY_VIEW',
  ACTIVITY_EDIT: 'ROLE_ACTIVITY_EDIT',
  INVOICE_VIEW: 'ROLE_INVOICE_VIEW',
  INVOICE_EDIT: 'ROLE_INVOICE_EDIT',
  SPOT_VIEW: 'ROLE_SPOT_VIEW',
  SPOT_EDIT: 'ROLE_SPOT_EDIT',
  SPORT_VIEW: 'ROLE_SPORT_VIEW',
  SPORT_EDIT: 'ROLE_SPORT_EDIT',
  COUNTRY_VIEW: 'ROLE_COUNTRY_VIEW',
  COUNTRY_EDIT: 'ROLE_COUNTRY_EDIT',
  COMMERCIAL_ZONE_VIEW: 'ROLE_COMMERCIAL_ZONE_VIEW',
  COMMERCIAL_ZONE_EDIT: 'ROLE_COMMERCIAL_ZONE_EDIT',
  FEEDBACK_VIEW: 'ROLE_FEEDBACK_VIEW',
  FEEDBACK_EDIT: 'ROLE_FEEDBACK_EDIT',
  BOOKING_VIEW: 'ROLE_BOOKING_VIEW',
  BOOKING_VIEW_ANY: 'ROLE_BOOKING_VIEW_ANY',
  BOOKING_EDIT: 'ROLE_BOOKING_EDIT',
  MAINTENANCE: 'ROLE_MAINTENANCE',
  PROMOCODE_EDIT: 'ROLE_PROMOCODE_EDIT',
  STATS_VIEW: 'ROLE_STATS_VIEW',
  TAGS_EDIT: 'ROLE_TAGS_EDIT',
  PARTNERS_EDIT: 'ROLE_PARTNERS_EDIT',
  TRACKING_CAMPAIGN_VIEW: 'ROLE_TRACKING_CAMPAIGN_VIEW',
  TRACKING_CAMPAIGN_EDIT: 'ROLE_TRACKING_CAMPAIGN_EDIT',
  SUBSCRIBER_VIEW: 'ROLE_SUBSCRIBER_VIEW',
  SUBSCRIBER_EDIT: 'ROLE_SUBSCRIBER_EDIT',
  DISTRIBUTION_CHANNEL_VIEW: 'ROLE_DISTRIBUTION_CHANNEL_VIEW',
  PRICING_EDIT_ANY: 'ROLE_PRICING_EDIT_ANY',
  INVOICE_VIEW_OWN_PROVIDER: 'ROLE_INVOICE_VIEW_OWN_PROVIDER',
  REVIEW_VIEW_OWN_PROVIDER: 'ROLE_REVIEW_VIEW_OWN_PROVIDER',

  ADMIN: 'ROLE_ADMIN',
}
