import { createStyles, Theme } from '@material-ui/core'

const styles = ({ spacing }: Theme) =>
  createStyles({
    paper: {
      padding: spacing(5),
      width: '400px',
      marginTop: spacing(10),
      marginBottom: spacing(2),
      marginLeft: 'auto',
      marginRight: 'auto',
    },

    offline: {
      textAlign: 'center',
      fontSize: 64,
      lineHeight: 1.1,
    },

    retry: {
      textAlign: 'center',
      marginTop: spacing(2),
    },
    caption: {
      textAlign: 'center',
    },
  })

export default styles
