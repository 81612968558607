import {
  NotificationActionTypes,
  NOTIFICATIONS_GET_LOADED_ERROR,
  NOTIFICATIONS_GET_LOADED_SUCCESS,
  NOTIFICATIONS_GET_LOADING,
} from './actionTypes'

import INotification from '../../_types/notification'

export interface INotificationState {
  loading: 0 | 1 | -1
  page: number
  notifications: INotification[]
  unread: number
}

const defaultState: INotificationState = {
  loading: 0,
  page: 1,
  notifications: [],
  unread: 0,
}

const notification = (
  state: INotificationState = defaultState,
  action: NotificationActionTypes,
): INotificationState => {
  switch (action.type) {
    case NOTIFICATIONS_GET_LOADING:
      return { ...state, loading: 1 }
    case NOTIFICATIONS_GET_LOADED_SUCCESS: {
      const { page } = state
      const { notifications, unread } = action
      return {
        ...state,
        loading: 0,
        notifications: page === 1 ? notifications : [...state.notifications, ...notifications],
        unread,
      }
    }
    case NOTIFICATIONS_GET_LOADED_ERROR:
      return { ...state, loading: -1 }
    default:
      return state
  }
}

export default notification
