import React from 'react'

import ErrorOutline from '@material-ui/icons/ErrorOutline'

import { Container, Label } from './Error.styled'

import { useTranslation } from 'react-i18next'

interface IProps {
  small?: boolean
}

const Error = ({ small }: IProps) => {
  const { t } = useTranslation()

  return (
    <Container style={{ height: small ? 112 : 190 }}>
      <ErrorOutline style={{ fontSize: small ? 60 : 127 }} />
      <Label style={{ fontSize: small ? 16 : 20 }}>{t('ui_Refresh_error')}</Label>
    </Container>
  )
}

export default Error
